import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


if(localStorage.access) {
    $('.product-img .action-wrapper').addClass( localStorage.access )
    $('.contact-form-link').addClass('disabled')
};

$('.vf-mask-trigger').hover(function(){
	var maskID = $(this).attr('mask-ref')
	$('.'+maskID).toggleClass('hover');
});

$('.ar-content-trigger').on('click', function() {
	var viewerPath = $(this).attr('viewer-path')
	var prodName = $(this).attr('prod-name')
	var lineName = $(this).attr('line-name')
	var prodNumber = $(this).attr('prod-number')
	$.ajax('assets/ar-data/'+viewerPath).
	  done(function(content) {
	   $('#ar-viewer-modal').foundation('open');
	   $('.ar-content').html(content);
	   $('.line-name').html(lineName);
	   $('.prod-name').html(prodName);
	   $('.prod-number').html(prodNumber);
	  });
});


$('.gallery-modal-trigger').on('click', function() {
	var projectURL = $(this).attr('project-url')
	$.ajax('assets/gallery-modals/'+ projectURL).
	  done(function(content) {
	   $('#gallery-modal').foundation('open');
	   $('#gallery-modal').html(content);
	   $(document).foundation();
	  });
});

// $(document).ready(function(){
// 	$('#feature-container').load('assets/featured-products/feature-test.html');
// });

$(function() {
   var max = 7, min = 1;
   $("#feature-container").load("assets/featured-products/feature-"+Math.floor(Math.random()*(max-min+1)+min)+".html"); 
});